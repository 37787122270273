<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
          <!-- 도급업체 -->
          <c-vendor
            name="vendorCd"
            label="도급업체"
            v-model="searchParam.vendorCd"
          ></c-vendor>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 예비품명 -->
          <c-datepicker
            type="date"
            :range="true"
            name="evalYearYmd"
            label="평가기간"
            v-model="searchParam.evalYearYmd"
          ></c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 평가등급 -->
          <c-select
            :comboItems="gradeItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="evalGradeCd"
            label="평가등급"
            v-model="searchParam.evalGradeCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="도급업체 평가 목록"
      tableId="evaluationResultSeq"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group>
            <c-btn label="등록" :editable="editable" icon="add" @btnClicked="onItemClick" />
            <c-btn label="검색" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import CDatepicker from '../../../components/CDatepicker.vue';
export default {
  components: { CDatepicker },
  name: "spare-parts",
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: "plantNm" },
          { index: 1, colName: 'vendorNm' }
        ],
        columns: [
          {
            name: "plantNm",
            field: "plantNm",
            label: "사업장",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "vendorNm",
            field: "vendorNm",
            label: "도급업체",
            style: 'width:120px',
            align: "center",
            sortable: true,
          },
          {
            label: '평가점수',
            style: 'width:80px',
            align: 'center',
            sortable: false,
            child: [
              {
                name: "totalGrade",
                field: "totalGrade",
                style: 'width:60px',
                label: "총점",
                align: "center",
                sortable: true,
              },
              {
                name: "evalTotalGrade",
                field: "evalTotalGrade",
                style: 'width:60px',
                label: "평점",
                align: "center",
                sortable: true,
              },
              {
                name: "evalGradeNm",
                field: "evalGradeNm",
                style: 'width:60px',
                label: "등급",
                align: "center",
                sortable: true,
              },
            ]
          },
          {
            name: "evalYearYmd",
            field: "evalYearYmd",
            label: "평가기간",
            style: 'width:150px',
            align: "center",
            sortable: true,
            type: "link",
          },
          {
            name: "remark",
            field: "remark",
            style: 'width:200px',
            label: "평가결과 종합",
            align: "left",
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        spareNm: null,
        purpose: null,
        manuCompany: null,
        evalGradeCd: null,
      },
      editable: true,
      searchUrl: "",
      popupOptions: {
        target: null,
        title: "",
        isFull: true,
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      gradeItems: [
        { code: '1', codeName: 'A등급' },
        { code: '2', codeName: 'B등급' },
        { code: '3', codeName: 'C등급' },
      ],
      isResult: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.grid.data = [
        {
          evaluationResultSeq: 1,
          plantNm: "사업장1",
          vendorNm: "업체1",
          evalGradeNm: 'A등급',
          evalYearYmd: "2021-01-25 ~ 2021-05-24",
          evalTotalGrade: "90점",
          totalGrade: '100점',
          remark: '평가결과 종합',
        },
        {
          evaluationResultSeq: 2,
          plantNm: "사업장1",
          vendorNm: "업체2",
          evalYearYmd: "2021-05-25 ~ 2021-08-14",
          evalGradeNm: 'A등급',
          evalTotalGrade: "80점",
          totalGrade: '100점',
          remark: '평가결과 종합',
        },
      ];
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.popupOptions.title = "도급업체 평가 상세"; // 도급업체 평가 상세
      this.popupOptions.param = {
        row: row,
      };
      this.popupOptions.target = () =>
        import(`${"./vendorAssessDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.height = '300px';
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    onItemClick() {
      this.popupOptions.target = () =>
      import(`${"./vendorAssessDetail.vue"}`);

      this.popupOptions.title = '도급업체 평가 상세'; // 도급업체 평가 상세
      this.popupOptions.param = null;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    btnCancel() {
      this.isResult = false;
    },
  },
};
</script>
